.depreciation {
    color: #FF4136;
}
.noremat{
  color: #97bf0d;
}
.noremat:hover{
  color: #008c87;
}
.assurance {
    color:#FFDC00;
}
.financement {
    color:#2ECC40;
}
.energie {
    color:#FF851B;
}
.maintenance {
    color:#AAAAAA;
}
.pneumatique {
    color:#111111;
}
.salaire {
    color:#B10DC9;
}
.formation {
    color:#39CCCC;
}

.input-group-text{
  background-color: #4c575f ;
  color: white;
}

/***
  *
  * Input Custom color noremat
  *
 ***/
input[type=range] {
    -webkit-appearance: none;
    width: 100%;
    margin: 3.9px 0;
  }
  input[type=range]:focus {
    outline: none;
  }
  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 9.2px;
    cursor: pointer;
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    background: #e6e6e6;
    border-radius: 25px;
    border: 0px solid rgba(1, 1, 0, 0.34);
  }
  input[type=range]::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #000031, 0px 0px 0px #00004b;
    border: 2.2px solid rgba(26, 26, 26, 0.12);
    height: 17px;
    width: 16px;
    border-radius: 50px;
    background: #97bf0d;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -3.9px;
  }
  input[type=range]:focus::-webkit-slider-runnable-track {
    background: #f3f3f3;
  }
  input[type=range]::-moz-range-track {
    width: 100%;
    height: 9.2px;
    cursor: pointer;
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    background: #e6e6e6;
    border-radius: 25px;
    border: 0px solid rgba(1, 1, 0, 0.34);
  }
  input[type=range]::-moz-range-thumb {
    box-shadow: 0px 0px 0px #000031, 0px 0px 0px #00004b;
    border: 2.2px solid rgba(26, 26, 26, 0.12);
    height: 17px;
    width: 16px;
    border-radius: 50px;
    background: #97bf0d;
    cursor: pointer;
  }
  input[type=range]::-ms-track {
    width: 100%;
    height: 9.2px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  input[type=range]::-ms-fill-lower {
    background: #d9d9d9;
    border: 0px solid rgba(1, 1, 0, 0.34);
    border-radius: 50px;
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  }
  input[type=range]::-ms-fill-upper {
    background: #e6e6e6;
    border: 0px solid rgba(1, 1, 0, 0.34);
    border-radius: 50px;
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  }
  input[type=range]::-ms-thumb {
    box-shadow: 0px 0px 0px #000031, 0px 0px 0px #00004b;
    border: 2.2px solid rgba(26, 26, 26, 0.12);
    height: 17px;
    width: 16px;
    border-radius: 50px;
    background: #97bf0d;
    cursor: pointer;
    height: 9.2px;
  }
  input[type=range]:focus::-ms-fill-lower {
    background: #e6e6e6;
  }
  input[type=range]:focus::-ms-fill-upper {
    background: #f3f3f3;
  }
  